<template>
    <v-card class="elevation-12 auth-form">
      <header-auth header-title="Iniciar sesión"></header-auth>
      <v-card-text>
        <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        >
          <v-text-field
            v-if="!userValidated"
            label="Email"
            placeholder="Ingresa tu email"
            v-model="email"
            :rules="emailRules"
            type="text"
          >
            <template v-slot:prepend-inner>
              <unicon name="envelope" fill="#5A5A5A"></unicon>
            </template>
          </v-text-field>

          <v-text-field
            v-if="userValidated"
            :type="showedPassword ? 'text' : 'password'"
            label="Contraseña"
            placeholder="Ingresa tu contraseña"
            :rules="passwordRules"
            v-model="password"
          >
              <template v-slot:prepend-inner>
              <unicon name="lock" fill="#5A5A5A"></unicon>
            </template> 
            <template v-slot:append>
              <unicon name="eye" fill="#5A5A5A" v-if="!showedPassword" v-on:click="togglePassword"></unicon>
              <unicon name="eye-slash" fill="#5A5A5A" v-else v-on:click="togglePassword"></unicon>
            </template> 
          </v-text-field>
          <v-flex class="red--text text-center" v-if="errorMessage">{{errorMessage}}</v-flex>
        </v-form>
      </v-card-text>
        <p class="text-center">
        <v-btn color="primary" :disabled="!valid || !emailValid" rounded :loading="processing" @click="login">
          {{userValidated ? 'Ingresar' : 'Continuar'}}
        </v-btn>
        </p>
        <p class="text-center">
          <v-btn text href="/recoverPassword" color="link">¿Olvidaste la contraseña?</v-btn>
        </p>
        <footer-auth />
    </v-card>
</template>
<script>
  import axios from 'axios';
  import FooterAuth from './components/FooterAuth.vue';
  import HeaderAuth from './components/HeaderAuth.vue';
  export default {
    components: {
      HeaderAuth,
      FooterAuth
    },
    data: () => ({
      email: '',
      password: '',
      showedPassword: false,
      userValidated: false,
      errorMessage: null,
      processing: false,
      valid: false,
      emailRules: [
        v => !!v || 'Campo requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail no válido',
      ],
      passwordRules: [
        v => !!v || 'Campo requerido',
      ]
    }),
    computed:{
      emailValid(){
        return this.email != '';
      }
    },
    methods: {
      togglePassword(){
        this.showedPassword = !this.showedPassword;
      },
      login(){
          this.errorMessage = null;
          this.processing = true;
          if(this.userValidated){
            axios.post('users/login', {email: this.email, password: this.password})
            .then(response => {
              return response.data;
            }).then(data => {
              this.$store.dispatch('saveToken', data.tokenReturn)
              axios.defaults.headers = {
                token: data.tokenReturn
              };
              this.$router.push({name:'home'})
              //window.location.reload();
            }).catch(e => {
              this.errorMessage = null;
              this.processing = false;
              if(e.response.status == 404){
                this.errorMessage = 'Contraseña incorrecta.';
              }else{
                this.errorMessage = 'Ocurrio un error con el servidor. Estamos resolviendo el problema.';
              }
            });
          }else{
            axios.post('users/validate', {email: this.email})
            .then(response => {
              return response.data;
            }).then(data => {
              this.userValidated = true;
              this.processing = false;
            }).catch(e => {
              this.processing = false;
              if(e.response.status == 404){
                this.errorMessage = 'Correo no registrado o no cuenta con permisos. Verifica si está escrito correctamente o ponte en contacto con un administrador.';
              }else{
                this.errorMessage = 'Ocurrio un error con el servidor. Estamos resolviendo el problema.';
              }
            });
          }

          
      }
    }
  }
</script>
<style scoped>
.disclaimer{
  padding: 10px;
}
.v-toolbar__title{
  font-size: 14px !important;
}
</style>